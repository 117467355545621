import { ImageList } from '../../../shared';

import * as mix from './mix.png';

export const mixImages: ImageList = [
    {
        caption: 'Mix UI Touch Homescreen',
        src: mix.default,
    },
];
